import React from "react";

function Logo() {
  return (
    <div>
      <svg
        className="logo"
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        x="0px"
        y="0px"
        viewBox="0 0 681 159.2"
      >
        <g id="XMLID_2_">
          <path
            id="XMLID_56_"
            className="st0"
            d="M88,130.6l-7.5-19.8H33.6l-7.5,19.8H5.7L45.9,28.3h22.4l40.2,102.3H88z M57.1,46.3L38.6,95
		h36.8L57.1,46.3z"
          />
          <path
            id="XMLID_51_"
            className="st0"
            d="M108.6,130.7H88l-7.5-19.8H33.7l-7.5,19.8H5.5L45.8,28.2h22.5l0,0.1L108.6,130.7z M88.1,130.5
		h20.2L68.2,28.4H45.9L5.8,130.5H26l7.5-19.8h47.1L88.1,130.5z M75.6,95.1H38.5l18.6-49.2L75.6,95.1z M38.8,94.9h36.5L57.1,46.6
		L38.8,94.9z"
          />
          <path
            id="XMLID_47_"
            className="st0"
            d="M113.6,130.6V28.3h50.3c18.9,0,29.1,11.7,29.1,26.1c0,12.7-8.3,21.5-17.8,23.5
		c11,1.7,19.8,12.6,19.8,25c0,16-10.4,27.8-29.8,27.8H113.6z M174.6,57.3c0-7.5-5.2-13.2-14.3-13.2h-28.8v26.4h28.8
		C169.4,70.5,174.6,65.1,174.6,57.3 M176.6,100.6c0-7.7-5.4-14.3-15.5-14.3h-29.6v28.5h29.6C170.8,114.8,176.6,109.5,176.6,100.6"
          />
          <path
            id="XMLID_40_"
            className="st0"
            d="M165.3,130.7h-51.8V28.2h50.4c9,0,16.5,2.7,21.7,7.7c4.9,4.6,7.5,11.2,7.5,18.5
		c0,6-1.9,11.5-5.4,15.8c-3.1,3.8-7.3,6.5-12,7.6c10.9,2,19.3,12.8,19.3,25c0,8.1-2.7,15-7.7,19.9
		C182.2,128,174.5,130.7,165.3,130.7 M113.7,130.5h51.6c9.2,0,16.8-2.7,22.1-7.9c5-4.9,7.6-11.7,7.6-19.8c0-12.3-8.7-23.2-19.7-24.9
		l-0.6-0.1l0.6-0.1c4.8-1,9.2-3.7,12.4-7.7c3.5-4.3,5.3-9.7,5.3-15.7c0-7.2-2.7-13.8-7.5-18.4c-5.2-5-12.7-7.6-21.6-7.6h-50.2V130.5
		z M161.1,114.9h-29.7V86.2h29.7c10.8,0,15.6,7.2,15.6,14.4c0,4.4-1.4,8-4.2,10.6C169.9,113.6,165.9,114.9,161.1,114.9 M131.6,114.7
		h29.5c9.6,0,15.4-5.3,15.4-14.2c0-7-4.8-14.2-15.4-14.2h-29.5V114.7z M160.4,70.6h-28.9V44h28.9c4.4,0,8,1.3,10.6,3.8
		c2.5,2.4,3.8,5.7,3.8,9.5c0,3.9-1.3,7.3-3.8,9.6C168.4,69.3,164.8,70.6,160.4,70.6 M131.6,70.4h28.7c8.7,0,14.2-5,14.2-13.1
		c0-3.8-1.3-7-3.7-9.3c-2.5-2.5-6.1-3.7-10.5-3.7h-28.7V70.4z"
          />
          <polygon
            id="XMLID_39_"
            className="st0"
            points="276,130.6 222.6,57.4 222.6,130.6 204.7,130.6 204.7,28.3 223.1,28.3 275.4,99.2 
		275.4,28.3 293.3,28.3 293.3,130.6 	"
          />
          <path
            id="XMLID_36_"
            className="st0"
            d="M293.5,130.7h-17.5l0,0l-53.2-72.9v72.9h-18.2V28.2h18.6l52.1,70.6V28.2h18.2V130.7z
		 M276.1,130.5h17.2V28.4h-17.7v71.1L223,28.4h-18.2v102.1h17.7V57.1L276.1,130.5z"
          />
          <path
            id="XMLID_33_"
            className="st0"
            d="M306.4,130.6V28.3h38c32.1,0,53.5,21.2,53.5,51.1c0,30.2-21.5,51.2-53.4,51.2H306.4z
		 M379.6,79.4c0-19.6-12.3-35.3-35-35.3h-20.2v70.7h20.1C366.7,114.8,379.6,98.9,379.6,79.4"
          />
          <path
            id="XMLID_28_"
            className="st0"
            d="M344.6,130.7h-38.3V28.2h38.1c7.9,0,15.3,1.3,21.9,3.8c6.5,2.4,12.2,6,16.9,10.6
		c4.7,4.6,8.4,10,10.9,16.2c2.6,6.3,3.9,13.3,3.9,20.6c0,7.5-1.3,14.5-3.9,20.8c-2.5,6.2-6.2,11.7-10.9,16.2
		c-4.7,4.6-10.4,8.1-16.9,10.6C359.8,129.5,352.5,130.7,344.6,130.7 M306.6,130.5h38.1c15.6,0,28.9-4.9,38.6-14.3
		c4.7-4.5,8.4-10,10.8-16.2c2.6-6.3,3.8-13.3,3.8-20.7c0-7.4-1.3-14.3-3.8-20.6c-2.5-6.2-6.2-11.6-10.9-16.1
		c-4.7-4.6-10.4-8.1-16.9-10.5c-6.6-2.5-14-3.8-21.8-3.8h-37.9V130.5z M344.5,114.9h-20.2V44h20.4c10.8,0,19.8,3.6,26.1,10.3
		c5.8,6.3,9,15.2,9,25.1c0,9.8-3.3,18.7-9.3,25.1C364.1,111.3,355.1,114.9,344.5,114.9 M324.5,114.7h20c10.5,0,19.5-3.6,25.8-10.4
		c5.9-6.3,9.2-15.2,9.2-24.9c0-9.9-3.2-18.7-8.9-24.9c-6.2-6.7-15.2-10.2-25.9-10.2h-20.1V114.7z"
          />
          <rect
            id="XMLID_27_"
            x="113.6"
            y="141.1"
            className="st0"
            width="78.9"
            height="18"
          />
          <path
            id="XMLID_24_"
            className="st0"
            d="M192.6,159.2h-79.2V141h79.2V159.2z M113.7,158.9h78.7v-17.7h-78.7V158.9z"
          />
          <g className="the-practice-animation">
            <polygon
              id="XMLID_23_"
              className="st1"
              points="433.6,28 469.9,28 469.9,31.7 453.9,31.7 453.9,73.8 449.6,73.8 449.6,31.7 
		433.6,31.7 	"
            />
            <path
              id="XMLID_22_"
              className="st1"
              d="M473.3,28h4v18.4h0.1c1.5-4,5.9-6.7,10.6-6.7c9.3,0,12.1,4.9,12.1,12.8v21.3h-4V53.1
		c0-5.7-1.9-10-8.4-10c-6.4,0-10.3,4.9-10.4,11.3v19.3h-4V28z"
            />
            <path
              id="XMLID_19_"
              className="st1"
              d="M510.2,58.3c0.1,5.9,3.1,13,10.9,13c5.9,0,9.1-3.5,10.4-8.5h4c-1.7,7.5-6.1,11.9-14.4,11.9
		c-10.5,0-14.9-8.1-14.9-17.5c0-8.7,4.4-17.5,14.9-17.5c10.6,0,14.9,9.3,14.6,18.6H510.2z M531.6,54.9c-0.2-6.1-4-11.8-10.5-11.8
		c-6.6,0-10.3,5.8-10.9,11.8H531.6z"
            />
            <path
              id="XMLID_16_"
              className="st1"
              d="M438.8,83.7H459c13.1,0,15.6,8.5,15.6,14.2c0,5.6-2.5,14.1-15.6,14h-12.2v17.6h-8V83.7z
		 M446.8,105.3h11.7c3.6,0,8-1.8,8-7.6c0-6-3.7-7.6-7.9-7.6h-11.9V105.3z"
            />
            <path
              id="XMLID_15_"
              className="st1"
              d="M480.5,96.3h6.9v6.4h0.1c0.8-3.5,5.2-7.3,9.7-7.3c1.7,0,2.1,0.1,2.8,0.2v7.1
		c-1-0.1-2.1-0.3-3.1-0.3c-5,0-9,4-9,11.3v15.8h-7.3V96.3z"
            />
            <path
              id="XMLID_12_"
              className="st1"
              d="M530.8,122.1c0,1.8,0.4,2.5,1.7,2.5c0.5,0,1,0,1.8-0.1v5.1c-1.1,0.4-3.4,0.8-4.6,0.8
		c-3,0-5.1-1-5.6-4c-2.9,2.8-7.6,4-11.5,4c-6,0-11.3-3.2-11.3-9.7c0-8.3,6.6-9.6,12.8-10.3c5.3-1,9.9-0.4,9.9-4.7
		c0-3.8-3.9-4.5-6.9-4.5c-4.1,0-7,1.7-7.3,5.3h-7.3c0.5-8.5,7.8-11,15.1-11c6.5,0,13.3,2.6,13.3,9.6V122.1z M523.5,112.8
		c-2.2,1.5-5.8,1.4-9,2c-3.1,0.5-6,1.7-6,5.5c0,3.3,4.2,4.2,6.7,4.2c3.2,0,8.2-1.7,8.2-6.3V112.8z"
            />
            <path
              id="XMLID_11_"
              className="st1"
              d="M561.1,107.4c-0.6-4-3.5-6.2-7.6-6.2c-3.8,0-9.1,2-9.1,12.1c0,5.5,2.4,11.4,8.8,11.4
		c4.2,0,7.2-2.8,7.9-7.6h7.3c-1.3,8.6-6.7,13.3-15.2,13.3c-10.4,0-16.1-7.4-16.1-17.1c0-10,5.5-17.8,16.3-17.8
		c7.7,0,14.2,3.9,14.9,12H561.1z"
            />
            <path
              id="XMLID_10_"
              className="st1"
              d="M571,96.3h5.5v-9.9h7.3v9.9h6.6v5.5h-6.6v17.7c0,3,0.3,4.5,3.5,4.5c1,0,2.1,0,3.1-0.3v5.6
		c-1.6,0.1-3.1,0.4-4.7,0.4c-7.6,0-9-2.9-9.2-8.5v-19.6H571V96.3z"
            />
            <path
              id="XMLID_7_"
              className="st1"
              d="M595.9,83.7h7.3v6.9h-7.3V83.7z M595.9,96.3h7.3v33.1h-7.3V96.3z"
            />
            <path
              id="XMLID_6_"
              className="st1"
              d="M633.5,107.4c-0.6-4-3.5-6.2-7.6-6.2c-3.8,0-9.1,2-9.1,12.1c0,5.5,2.4,11.4,8.8,11.4
		c4.2,0,7.2-2.8,7.9-7.6h7.3c-1.3,8.6-6.7,13.3-15.2,13.3c-10.4,0-16.1-7.4-16.1-17.1c0-10,5.5-17.8,16.3-17.8
		c7.7,0,14.2,3.9,14.9,12H633.5z"
            />
            <path
              id="XMLID_3_"
              className="st1"
              d="M652.5,114.7c0,5.2,2.8,9.8,8.9,9.8c4.2,0,6.8-1.9,8.1-5.5h6.9c-1.6,7.2-7.8,11.3-15,11.3
		c-10.4,0-16.2-7.2-16.2-17.4c0-9.4,6.2-17.5,16-17.5c10.5,0,16.9,9.4,15.6,19.4H652.5z M669.6,109.9c-0.3-4.6-3.4-8.8-8.3-8.8
		c-5.1,0-8.5,3.9-8.7,8.8H669.6z"
            />
          </g>
        </g>
      </svg>
    </div>
  );
}

export default Logo;
