import React from "react";
import Banner from "../Components/Banner";

const ErrorPage = () => {
  return (
    <Banner>
      <h1>Error Page</h1>
    </Banner>
  );
};

export default ErrorPage;
